<template>
<!-- 物流管理 -->
<div class="log_body">
  <div class="addbtn">
    <el-button type="warning" size="small" @click="addbtn()">添加</el-button>
  </div>
  <el-table id="out-table" ref="productTable" :data="list" style="width: 100%" v-loading="listLoading" border>
    <el-table-column label="仓库名称" width="" align="center">
      <template #default="scope">{{scope.row.warehouseName}}</template>
    </el-table-column>
    <el-table-column label="出仓/入仓" width="" align="center">
      <template #default="scope">{{scope.row.entryAndExitWarehouse==1?'入仓':'出仓'}}</template>
    </el-table-column>
    <el-table-column label="客户名称" width="" align="center">
      <template #default="scope">{{scope.row.memberName}}</template>
    </el-table-column>
    <el-table-column label="联系电话" width="120" align="center">
      <template #default="scope">{{scope.row.contactNumber}}</template>
    </el-table-column>
    <el-table-column label="货物详情" width="" align="center">
      <template #default="scope">{{scope.row.cargoDetails}}</template>
    </el-table-column>
    <el-table-column label="物流负责人" width="" align="center">
      <template #default="scope">{{scope.row.logisticsManager}}</template>
    </el-table-column>
    <el-table-column label="物流信息" width="" align="center">
      <template #default="scope">{{scope.row.logisticsInformation}}</template>
    </el-table-column>
    <el-table-column label="预约时间" width="" align="center">
      <template #default="scope">{{scope.row.makeAnAppointmentTime}}</template>
    </el-table-column>
    <el-table-column label="审核状态" width="" align="center">
      <template #default="scope">{{
        scope.row.auditStatus==0?'未审核':
        scope.row.auditStatus==1?'审核通过':
        scope.row.auditStatus==2?'审核不通过':
        '未知状态'}}
        <el-tag v-if="scope.row.auditStatus==2" size="small" type="warning">{{scope.row.reviewComments}}</el-tag>
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      width="150"
      label="操作">
      <template #default="scope">
        <el-button v-if="scope.row.auditStatus!=1" type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination-container"  v-if="total<listQuery.pageSize?true:true">
    <el-pagination
      background
      :page-size="listQuery.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="total"
      v-model:current-page="listQuery.pageNum"
      layout="prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>
  </div>
  <div class="log_dialog">
    <el-dialog
      v-model="dialogVisible"
      :title="!countryIntro.id ? '新增' : '修改'"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form :model="countryIntro"
              :rules="rules"
              ref="countryIntroFrom"
              label-width="150px"
              size="small">
        <el-form-item label="仓库名称：" prop="warehouseId">
          <el-select v-model="countryIntro.warehouseId" filterable placeholder="请选择"
          :loading="warehouselistLoading"
          @change="warehouseName">
            <el-option
              v-for="item in warehouseoptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型：" prop="entryAndExitWarehouse">
          <el-select v-model="countryIntro.entryAndExitWarehouse" filterable placeholder="请选择"
          :loading="typelistLoading">
            <el-option
              v-for="item in typeroptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流负责人：" prop="priority">
          <el-select v-model="countryIntro.logisticsManager" filterable placeholder="请选择"
          :loading="userlistLoading">
            <el-option
              v-for="item in useroptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预约时间：" prop="makeAnAppointmentTime">
          <el-date-picker
            v-model="countryIntro.makeAnAppointmentTime"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="货物详情：" prop="cargoDetails">
          <el-input v-model="countryIntro.cargoDetails" class="input-width"></el-input>
        </el-form-item>
        <el-form-item label="联系方式：" prop="contactNumber">
          <el-input v-model="countryIntro.contactNumber" class="input-width"></el-input>
        </el-form-item>
        <el-form-item label="物流详情：">
          <el-input v-model="countryIntro.logisticsInformation" class="input-width"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-if="!countryIntro.id" type="primary" @click="onSubmit()" :disabled="disabledbtn">提交新增</el-button>
          <el-button v-else type="primary" @click="updateSubmit()" :disabled="disabledbtn">提交修改</el-button>
          <!-- <el-button v-if="!isEdit" @click="resetForm('countryIntroFrom')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import http from '../../../api/http'
import { getStore } from '../../../tools/storage'
// 分页查询仓库信息
const warehouseList = (data) => {
  return http.get('/mall-merchant/warehouse/list', data)
}
// 保存物流信息
const saveLogisticsInformation = (data) => {
  return http.post('/mall-merchant/omsLogisticInfo/saveLogisticsInformation', data, true)
}
// 修改物流信息
const updateomsLogisticInfo = (data) => {
  return http.post('/mall-merchant/omsLogisticInfo/update', data, true)
}
// 分页查询物流信息
const omsLogisticInfoList = (data) => {
  return http.get('/mall-merchant/omsLogisticInfo/list', data)
}
export default {
  name: 'logisticsMent',
  data () {
    return {
      statusOptions: [],
      rukuDate: '',
      rules: {
        makeAnAppointmentTime: [
          { required: true, message: '请选择期望预约时间', trigger: 'blur' }
        ],
        warehouseId: [
          { required: true, message: '请选择仓库名称', trigger: 'blur' }
        ],
        entryAndExitWarehouse: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        cargoDetails: [
          { required: true, message: '请输入货物详情', trigger: 'blur' }
        ],
        contactNumber: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ]
      },
      list: [],
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        memberId: null
      },
      warehouseQuery: {
        pageNum: 1,
        pageSize: 100
      },
      total: 0,
      listLoading: false,

      countryIntro: {
        logisticsManager: '平台负责',
        entryAndExitWarehouse: 1, // 0->出仓；1->入仓
        warehouseId: '',
        warehouseName: '',
        makeAnAppointmentTime: '',
        cargoDetails: '',
        logisticsInformation: '',
        contactNumber: ''
      },
      dialogVisible: false,
      disabledbtn: false,

      warehouseoptions: [],
      warehouselistLoading: false,
      useroptions: [
        {
          value: '平台负责',
          label: '平台负责'
        },
        {
          value: '自己负责',
          label: '自己负责'
        }
      ],
      userlistLoading: false,
      typeroptions: [
        {
          value: 0,
          label: '出仓'
        },
        {
          value: 1,
          label: '入仓'
        }
      ],
      typelistLoading: false
    }
  },
  components: {},
  methods: {
    getwarehouseDataList () { // 查仓库
      this.warehouselistLoading = true
      warehouseList(this.warehouseQuery).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          this.warehouseoptions = res.data.data.records
          this.warehouselistLoading = false
          this.dialogVisible = true
        }
      }).catch((err) => {
        this.warehouselistLoading = false
        console.log(err)
      })
    },
    async addbtn () {
      this.getwarehouseDataList()
    },
    handleClose (done) {
      this.$confirm('确认关闭?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.dataForm = {}
          done()
        })
        .catch(() => {})
    },
    warehouseName (val) { // 仓库名称
      // console.log(val);
      for (const i of this.warehouseoptions) {
        if (i.id === val) {
          this.countryIntro.warehouseName = i.name
          return
        } continue
      }
    },
    getDataList () { // 查询当前用户的物流管理信息
      this.listLoading = true
      const memberId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')).id : null
      this.listQuery.memberId = memberId
      omsLogisticInfoList(this.listQuery).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          this.list = res.data.data.records
          this.total = parseInt(res.data.data.total)
        }
        this.listLoading = false
      }).catch((err) => {
        this.listLoading = false
        console.log(err)
      })
    },
    handleSizeChange (val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getDataList()
    },
    handleCurrentChange (val) {
      this.listQuery.pageNum = val
      this.getDataList()
    },
    onSubmit () { // 提交新增
      console.log(this.countryIntro)
      this.disabledbtn = true
      saveLogisticsInformation(JSON.parse(JSON.stringify(this.countryIntro))).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.countryIntro = {
            logisticsManager: '平台负责',
            entryAndExitWarehouse: 1, // 0->出仓；1->入仓
            warehouseId: '',
            warehouseName: '',
            makeAnAppointmentTime: '',
            cargoDetails: '',
            logisticsInformation: '',
            contactNumber: ''
          }
          this.dialogVisible = false
          this.listQuery.pageNum = 1
          this.getDataList()
        } else {
          ElMessage.warning({
            message: res.data.msg
          })
        }
        this.disabledbtn = false
      }).catch((err) => {
        console.log(err)
        this.disabledbtn = false
      })
    },
    updateSubmit () { // 提交修改
      console.log(this.countryIntro)
      if (this.countryIntro.auditStatus === 2) {
        this.countryIntro.auditStatus = 0
      }
      this.disabledbtn = true
      updateomsLogisticInfo(JSON.parse(JSON.stringify(this.countryIntro))).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.dialogVisible = false
          this.listQuery.pageNum = 1
          this.getDataList()
        } else {
          ElMessage.warning({
            message: res.data.msg
          })
        }
        this.disabledbtn = false
      }).catch((err) => {
        console.log(err)
        this.disabledbtn = false
      })
    },
    addOrUpdateHandle (row) { // 修改按钮
      console.log(row)
      this.countryIntro = row
      // this.dialogVisible = true
      this.getwarehouseDataList()
    }
  },
  mounted () {
    this.getDataList()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.log_body{
  .addbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .pagination-container{
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .input-width {
    width: 70%;
  }
}
</style>
