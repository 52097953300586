<template>
  <div class="content_form">
    <el-form
    :model="formData"
    label-width="120px"
    class="demo-ruleForm"
    ref="formData"
    :rules="rules"
    >
      <div id="essent" class="form_item">
        <span class="fitem_title">{{$t('m.merchantworkbench.Basic_information')}}</span>
        <el-form-item :label="$t('m.index.product_name')+'：'"
        prop="name">
          <el-input v-model="formData.name" maxlength="50" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.index.variety')+'：'"
        prop="subject_value">
          <el-select v-model="formData.subject_value"
              size="mini">
                <el-option
                  v-for="item in formData.subject_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item :label="$t('m.reservation_varietiy_details.brand')+'：'"
        prop="brand">
          <el-input  maxlength="10" v-model="formData.brand" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.producer')+'：'"
        prop="placeOfOrigin">
          <el-input  maxlength="10" v-model="formData.placeOfOrigin" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.index.price')+'：'"
        prop="price">
          <el-input maxlength="8"
          v-model="formData.price"
          @change="confirmblAdbill('jg')"
          size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Unit_of_measurement')+'：'"
        prop="unitOfMeasurement_value">
          <el-select v-model="formData.unitOfMeasurement_value"
           size="mini">
            <el-option
              v-for="item in formData.unitOfMeasurement_option"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Specification_settings')+'：'" class="sptise">
          <table id="tfhover" class="tftable">
            <tr>
              <th v-for="i in formData.specification_dataTitle" :key="i">
                {{i}}
              </th>
            </tr>
            <tr class="heng" v-for="(item, index) in formData.specification_listData" :key="index">
              <td>{{
                item.spData[1].value
                }}</td>
              <td v-if="formData.previewStatus!==0">{{
                item.spData[0].value
                }}</td><!--  交货日期 -->
              <!-- <td>{{item.wareName}}</td> -->
              <td class="heng_price">
                {{formData.currency_value+item.price.toFixed(2)}}/{{formData.unitOfMeasurement_value}}
              </td>
              <td>{{item.stock+formData.unitOfMeasurement_value}}</td>
              <td class="updata_img">
                <el-upload
                  list-type="picture-card"
                  :before-upload="guigebeforeUpload"
                  :on-success="guigeuploadSuccess"
                  :on-preview="handlePictureCardPreview"
                  :action="upLoadUrl"
                  :data="upLoadData"
                  :on-remove="guigehandleRemove"
                  :on-change="guigeonChange"
                  accept="jpg/jpeg/png"

                  v-model:file-list="guigepic[index]"
                >
                <p @click="imgIndex=index">{{item.pic?$t('m.merchantworkbench.replace'):$t('m.merchantworkbench.upload')}}</p>
                </el-upload>
                <el-dialog v-model="imgShowBig">
                  <img :src="item.pic" alt="" />
                </el-dialog>
              </td>
              <td><span class="sptitebtn" @click="removeDatathis(item,index)">{{$t('m.buy.delete')}}</span></td>
            </tr>
          </table>
          <el-button size="mini" @click="this.GuigeWayDialog = true">{{$t('m.merchantworkbench.Add')}}</el-button>
        </el-form-item>
      </div>

      <el-divider style="background:#EAEAEA;"></el-divider> <!-- 分割线 -->

      <div id="rulese" class="form_item">
        <span class="fitem_title">{{$t('m.merchantworkbench.Rule_settings')}}</span>
        <div class="ruleseleft">
          <el-form-item :label="$t('m.reservation_varietiy_details.way_of_quoting')+'：'"
            prop="priceTerms_value">
              <el-select v-model="formData.priceTerms_value"
              size="mini">
                <el-option
                  v-for="item in formData.priceTerms_option"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.Currency')+'：'"
            prop="currency_value">
            <el-select v-model="formData.currency_value"
            size="mini">
              <el-option
                v-for="item in formData.currency_option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('m.reservation_varietiy_details.Prepayment_ratio')+'：'" v-if="formData.previewStatus !== 0" class="biliitem"
          prop="advanceRatio">
            <el-input class="advbili"
             maxlength="3"
             v-model="formData.advanceRatio"
             @change="confirmblAdbill('yf')" size="mini"></el-input>
            <span class="bili">%</span>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.Minimum_increment')+'：'"
          prop="leastIncrement">
            <el-input maxlength="8"
            v-model="formData.leastIncrement"
            @change="confirmblAdbill('zl')" size="mini"></el-input>
          </el-form-item>
        </div>
        <div class="ruleseright">
          <el-form-item :label="$t('m.merchantworkbench.Expiry_date_of_offer')+'：'"
            prop="offerTime">
            <div class="block">
              <el-date-picker v-model="formData.offerTime"
               size="mini"
               type="date" :placeholder="$t('m.merchantworkbench.option_date')"
               value-format="YYYY-MM-DD"
               :disabledDate="disabledDate"
               >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('m.buy_settleaccounts.Payment_Methods')+'：'"
          prop="modeOfPayment_value">
            <el-select v-model="formData.modeOfPayment_value"
            size="mini">
              <el-option
                v-for="item in formData.modeOfPayment_option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.Permissible_overflow_ratio')+'：'" v-if="formData.previewStatus !== 0" class="biliitem"
          prop="allowOverflowRatio">
            <el-input class="advbili"
            v-model="formData.allowOverflowRatio"
            maxlength="2"
            @change="confirmblAdbill('yz')" size="mini"></el-input>
            <span class="bili" style="color:#333333;">%</span>
            <span class="bili">{{$t('m.merchantworkbench.Maximum_10')}}</span>
          </el-form-item>
          <el-form-item :label="$t('m.reservation_varietiy_details.Minimum_Order_Quantity')+'：'"
          prop="leastIndent">
            <el-input maxlength="8"
            v-model="formData.leastIndent"
            @change="confirmblAdbill('dh')"
            size="mini"></el-input>
          </el-form-item>
        </div>

        <el-form-item :label="$t('m.reservation_varietiy_details.Delivery_method')+'：'" class="withho" v-if="formData.previewStatus !== 0">
          <table id="tfhover" class="tftable">
            <tr>
              <th v-for="i in formData.withholdingway_dataTitle" :key="i">
                {{i}}
              </th>
            </tr>
            <tr class="heng" v-for="(item, index) in formData.withholdingway_listData" :key="index">
              <td>{{item.spec}}</td>
              <td>
                {{formData.currency_value+item.price.toFixed(2)}}/{{formData.unitOfMeasurement_value}}
              </td>
              <!-- <td>{{item.minimum}}{{formData.unitOfMeasurement_value}}</td> -->
              <td><span class="withhobtn" @click="removeytDataNow(item,index)">{{$t('m.buy.delete')}}</span></td>
            </tr>
          </table>
          <el-button size="mini" @click="this.ytWayDialog = true">{{$t('m.merchantworkbench.Add')}}</el-button>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Resale_allowed')+'：'" v-if="formData.previewStatus!==0"
         required class="turnma"
          prop="turnMarket">
          <el-radio v-model="formData.turnMarket" label="0" size="mini">{{$t('m.reservation_varietiy_details.Resale_not_allowed')}}</el-radio>
          <el-radio v-model="formData.turnMarket" label="1" size="mini">{{$t('m.reservation_varietiy_details.Resell_allowed')}}</el-radio>
        </el-form-item>
        <div class="zhuanshoutime">
          <!-- <el-form-item label="转售终止时间：" class="turntime" required
            prop="turnMarketTerminationTime">
            <div class="block">
                <el-date-picker v-model="formData.turnMarketTerminationTime"
                size="mini"
                type="date" placeholder="选择日期"
                value-format="YYYY-MM-DD"
                :disabledDate="disabledDate"
                >
                </el-date-picker>
            </div>
          </el-form-item> -->
          <el-form-item :label="$t('m.merchantworkbench.Number_of_days_in_advance')+'：'" v-if="formData.previewStatus!==0"
          class="turntime"
            prop="bringForwardTime_begin">
              <el-input class="turntime_input"
              v-model="formData.bringForwardTime_begin"
              maxlength="3"
              @change="confirmblAdbill('ytbegin')"
              size="mini"><template #append>{{$t('m.reservation_varietiy_details.day')}}</template></el-input>
              ——
              <el-input class="turntime_input"
              v-model="formData.bringForwardTime_finish"
              maxlength="3"
              @change="confirmblAdbill('ytfinish')"
              size="mini"><template #append>{{$t('m.reservation_varietiy_details.day')}}</template></el-input>
          </el-form-item>
        </div>
        <el-form-item :label="$t('m.merchantworkbench.Settlement_warehouse')+'：'"
          prop="dealWarehouse_value">
            <!-- <el-input v-model="formData.dealWarehouse" size="mini"></el-input> -->
            <el-select v-model="formData.dealWarehouse_value"
              size="mini">
                <el-option
                  v-for="item in formData.dealWarehouse_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <!-- <el-form-item label="供货商：">
            <el-input v-model="formData.supplier" size="mini"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="商品属性参数：" class="suppli">
            <el-input v-model="formData.commodityPropertyParameter" size="mini"></el-input>
        </el-form-item> -->
      </div>
      <!-- 咖啡设置 -->
      <div id="coffeeset" class="form_item">
        <span class="fitem_title">{{$t('m.merchantworkbench.Coffee_ettings')}}</span>
        <div class="ruleseleft">
          <el-form-item :label="$t('m.merchantworkbench.Coffee_Estate')+'：'"
            prop="manor_name">
              <el-input  maxlength="10" v-model="formData.manor_name" size="mini"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.Manor_introduction')+'：'"
            v-if="formData.previewStatus==0"
            prop="introductionToTheEstate">
              <el-input
              v-model="formData.introductionToTheEstate"
              :autosize="{ minRows: 2, maxRows: 10 }"
              type="textarea"
              :placeholder="$t('m.merchantworkbench.words')"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.grade')+'：'"
            prop="grade">
              <el-input  maxlength="10" v-model="formData.grade" size="mini"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.Year_of_harvest')+'：'" prop="productTime">
            <div class="block">
              <el-date-picker v-model="formData.productTime"
               size="mini"
               type="month" :placeholder="$t('m.merchantworkbench.option_date')"
               value-format="YYYY-MM"
               >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.treeSpecies')+'：'"
          prop="treeSpecies">
              <el-input maxlength="10" v-model="formData.treeSpecies" size="mini"></el-input>
          </el-form-item>
        </div>
        <div class="ruleseright">
          <el-form-item :label="$t('m.merchantworkbench.Planting_altitude')+'：'"
            prop="altitude">
              <el-input v-model="formData.altitude" maxlength="4"
              @change="confirmblAdbill('altitude')" size="mini"></el-input>{{$t('m.merchantworkbench.metre')}}
          </el-form-item>
          <el-form-item :label="$t('m.reservation_varietiy_details.Picking_method')+'：'"
          prop="pick_way">
              <el-input maxlength="10" v-model="formData.pick_way" size="mini"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.authentication')+'：'"
          prop="authentication">
              <el-input maxlength="10" v-model="formData.authentication" size="mini"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.cuppingReference')+'：'"
          prop="cuppingReference">
              <el-input maxlength="10" v-model="formData.cuppingReference" size="mini"></el-input>
          </el-form-item>
        </div>
        <div class="ruleserbottom">
           <el-form-item :label="$t('m.merchantworkbench.processing_method')+'：'"
            prop="process_way">
            <el-radio-group v-model="formData.process_way">
              <el-radio v-for="item in formData.process_list"
                :key="item.value"
               :label="item.label">{{item.value}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('m.merchantworkbench.Flavor_description')+'：'" class="fengwei"
          prop="flavor_description">
            <el-input
              v-model="formData.flavor_description"
              :autosize="{ minRows: 2, maxRows: 10 }"
              type="textarea"
              :placeholder="$t('m.merchantworkbench.words')"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
        </div>
      </div>
      <!-- 规则设置 -->
      <div id="decrip" class="form_item">
        <span class="fitem_title">{{$t('m.merchantworkbench.Rule_settings')}}</span>
        <el-form-item :label="$t('m.merchantworkbench.Freight')+'：'" v-if="formData.previewStatus==0"
          prop="feightPrice">
          <el-radio-group v-model="formData.isfeightchose" @change="isfeightchoseChang">
            <el-radio label="0" size="mini">{{$t('m.merchantworkbench.domestic')}}</el-radio>
            <el-radio label="1" size="mini">{{$t('m.merchantworkbench.foreign')}}</el-radio>
          </el-radio-group>
          <br>
          {{formData.isfeightchose==0?'￥ ':'$ '}}<el-input
          v-model="formData.feightPrice"  maxlength="8"
          @change="confirmblAdbill('yunfei')"
          size="mini"></el-input>{{" / "+formData.unitOfMeasurement_value}}
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Main_product_image')+'：'">
          <el-upload
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
            :on-preview="handlePictureCardPreview"
            :action="upLoadUrl"
            :data="upLoadData"
            :on-remove="handleRemove"
            v-model:file-list="fileList"

            list-type="picture-card"
            :limit="5"
            accept="jpg/jpeg/png/gif"
          >
            <el-icon><plus /></el-icon>
          </el-upload>
          <el-dialog v-model="formData.decript.dialogVisible">
            <img :src="formData.decript.dialogImageUrl" alt="" />
          </el-dialog>
          <div class="decripyt_div">
            <span class="decripyt_div_sapn">{{$t('m.merchantworkbench.Upload_up')}}</span>
            <p class="decripyt_div_p">{{$t('m.merchantworkbench.Supports_JPG')}}</p>
            <!-- <p class="decripyt_div_p">尺寸800px*800px，大小不超过500k</p> -->
          </div>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Product_detail_page')+'：'">
          <p class="pppp">（{{$t('m.merchantworkbench.Note_Please')}}）</p>
        </el-form-item>
        <el-form-item v-if="formStatus != 2 && formStatus != 3">
          <div id="editorshow" v-show="!formData.decript.dialogVisible">
            <editor-show @EditorFrom="(data)=>{editorDataList(data)}"></editor-show>
          </div>
        </el-form-item>
        <el-form-item v-if="formStatus == 2 || formStatus == 3">
          <!-- <div v-html="formData.decript.decriptList">
          </div> -->
          <div id="editorshow" v-show="!formData.decript.dialogVisible">
            <editor-show :value="formData.decript.decriptList"
            :toolbar="'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image table | removeformat fullscreen'"
             @EditorFrom="(data)=>{editorDataList(data)}"></editor-show>
          </div>
        </el-form-item>
        <el-form-item class="form_btn">
          <el-button class="submit_btn"
          :loading="loading"
          @click="submitForm()"
          >{{$t('m.merchantworkbench.submit')}}</el-button>
          <!-- <el-button @click="resetForm()"
          >取消</el-button> -->
        </el-form-item>
      </div>
    </el-form>
  </div>

  <!-- 设置规格弹窗 -->
  <el-dialog
    title="新增规格"
    v-model="GuigeWayDialog"
    width="30%"
    destroy-on-close
  >
    <el-form
      label-suffix="："
      :label-position="'left'"
      label-width="80px"
      :model="guigeDataNow"
    >
      <el-form-item label="规格" required
        label-width="100px">
        <el-input
          maxlength="8"
          v-model="guigeDataNow.spData[1].value"
          placeholder="请输入规格"
        ></el-input>
      </el-form-item>
      <el-form-item label="销售价格"
        required label-width="100px">
        <el-input maxlength="8"
          v-model="guigeDataNow.price"
          @change="confirmblAdbill('ggjg')"
          placeholder="请输入销售价格"
        ></el-input>
      </el-form-item>
      <el-form-item label="交货日期" v-if="formData.previewStatus!==0" required label-width="100px">
      <!-- <el-input v-model="form.toSellDay"></el-input> -->
        <div class="block">
          <el-date-picker
          v-model="guigeDataNow.spData[0].value"
          type="date"
          placeholder="请输入交货日期"
          :disabledDate="disabledDate"
          value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="库存" required label-width="100px">
        <el-input
          v-model="guigeDataNow.stock"
          maxlength="8"
          @change="confirmblAdbill('ggstock')"
          placeholder="请输入库存"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="this.GuigeWayDialog = false">取 消</el-button>
      <el-button type="primary" @click="confirmGuigeWay()">确 定</el-button>
    </div>
  </el-dialog>
  <!-- 预提方式弹窗 -->
  <el-dialog
    title="新增预提方式"
    v-model="ytWayDialog"
    width="30%"
    destroy-on-close
  >
    <el-form
      label-suffix="："
      :label-position="'left'"
      label-width="80px"
      :model="ytDataNow"
    >
      <el-form-item label="规格" required
        label-width="100px">
        <el-input maxlength="10"
          v-model="ytDataNow.spec"
          placeholder="请输入规格"
        ></el-input>
      </el-form-item>
      <el-form-item label="价格"
        required label-width="100px">
        <el-input  maxlength="8"
          v-model="ytDataNow.price"
          @change="confirmblAdbill('ytjg')"
          placeholder="请输入价格"
        ></el-input>
      </el-form-item>
      <el-form-item label-width="100px">
        <p style="color: #999999;"><el-icon><warning-filled /></el-icon>
        规格是包装规格/运输规格，价钱是包装价钱/运输价钱</p>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="this.ytWayDialog = false">取 消</el-button>
      <el-button type="primary" @click="confirmytWay">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCurrentInstance, reactive, ref, toRaw } from 'vue'
import { ElMessage } from 'element-plus'
import EditorShow from '../../../components/EditorShow.vue'
import http from '../../../api/http.js'
import emitter from '../../../tools/bus'
// import { policy } from '../../../api/oss.js'

// import Success from '../../components/Success.vue'

// 重新发布接口
const againSub = (id, data) => {
  return http.post(`mall-merchant/product/update/${id}`, data, true)
}
export default {
  components: { EditorShow },
  name: 'TradeFrom',
  data () {
    return {
      ossUrl: '',
      toBox: ref(''),
      imgShowBig: ref(false),
      formStatus: '', // 是什么发布  // 0：普通发布 1：现货发布  2：预售重新发布 3: 现货重新发布
      formData: reactive({
        id: '',
        // 基本信息
        previewStatus: this.$props.preSta === 0 ? 0 : 1, // 是否为预售商品：0->不是；1->是
        name: '', // 商品名称
        subject_value: '', // 已选品种(专题)
        subject_list: [], // 品种(专题)列表
        brand: '', // 品牌
        placeOfOrigin: '', // 产地
        price: '', // 价格
        unitOfMeasurement_option: [{ value: '吨', label: '吨' }, { value: 'KG', label: 'KG' }], // 所有单位
        unitOfMeasurement_value: 'KG', // 已选单位  // 计量单位

        // specification_dataTitle: ['规格', '交货日期', '销售价格', '库存', '属性图片', '操作'],
        specification_dataTitle: [
          this.$t('m.reservation_varietiy_details.specification'),
          this.$t('m.personalcenter_myorder.delivery_date'),
          this.$t('m.merchantworkbench.Sale_price'),
          this.$t('m.reservation_varietiy_details.guigeStock'),
          this.$t('m.merchantworkbench.Property_picture'),
          this.$t('m.auction.operate')
        ],
        specification_listData: [],
        specification_value: '', // 规格
        // 规格设置

        priceTerms_option: [
          { value: '自提', label: '自提' },
          { value: '物流', label: '物流' },
          { value: 'EXW', label: 'EXW' },
          { value: 'FOB', label: 'FOB' },
          { value: 'CIF', label: 'CIF' },
          { value: 'CFR', label: 'CFR' }
        ], // 所有报价方式
        priceTerms_value: '物流', // 已选报价方式 // 报价方式
        offerTime: '', // 报价有效日期

        currency_option: [{ value: '￥', label: '人民币' }, { value: '$', label: '美元' }], // 所有币种
        currency_value: '￥', // 已选 // 币种
        modeOfPayment_option: [
          { value: '在线支付', label: '在线支付' },
          { value: 'T/T电汇', label: 'T/T电汇' },
          { value: 'D/P', label: 'D/P' },
          { value: 'D/A', label: 'D/A' },
          { value: 'L/C', label: 'L/C' },
          { value: 'D/D(票汇)', label: 'D/D(票汇)' },
          { value: 'M/T(信汇)', label: 'M/T(信汇)' }
        ], // 所有支付方式
        modeOfPayment_value: '在线支付', // 已选 支付方式
        advanceRatio: '', // 预付比例
        allowOverflowRatio: '', // 允许溢折比例
        leastIncrement: '', // 最小增量
        leastIndent: '', // 最小订货量

        withholdingway_dataTitle: ['规格', '价格/单位', '操作'],
        withholdingway_listData: [],
        // withholdingway_value: '', // 预提方式
        turnMarket: '1', // 是否允许转售
        // turnMarketTerminationTime: '', // 转售终止时间
        bringForwardTime: '', // 预提提前天数
        bringForwardTime_begin: '', // 开始
        bringForwardTime_finish: '', // 结束
        dealWarehouse_list: [], // 交收仓库
        dealWarehouse_value: '', // 选择的交收仓库(站点)
        dealWarehouse_valueid: '', // 选择的交收仓库id(站点)
        supplier: '', // 供货商
        commodityPropertyParameter: '', // 商品属性参数
        productTime: '', // 产品年份
        decript: { // 商品介绍
          dialogImageUrl: ref(''), // 放大弹窗图片
          dialogVisible: ref(false), // 放大弹窗开关
          propertyImage: [], // 属性图片url
          url: [], // 图片地址
          decriptList: '' // 商品介绍
        },

        // 现货额外内容
        isfeightchose: '0',
        feightPrice: 0, // 运费 freightAbroad 国外   freightChina 国内
        manor_name: '', // 咖啡庄园名称
        introductionToTheEstate: '', // 庄园介绍
        grade: '', // 等级
        authentication: '', // 认证
        cuppingReference: '', // 杯测参考
        treeSpecies: '', // 树种
        altitude: '', // 种植海拔
        pick_way: '', // 采摘方式
        // process_list: [
        //   { value: '三重厌氧', label: '三重厌氧' },
        //   { value: '双重发酵日晒', label: '双重发酵日晒' },
        //   { value: '康普茶发西都日晒', label: '康普茶发西都日晒' },
        //   { value: '日晒', label: '日晒' },
        //   { value: '有氧发酵日晒', label: '有氧发酵日晒' },
        //   { value: '水洗', label: '水洗' },
        //   { value: '水洗96小时发酵', label: '水洗96小时发酵' },
        //   { value: '湿刨', label: '湿刨' },
        //   { value: '湿刨处理', label: '湿刨处理' },
        //   { value: '密处理', label: '密处理' },
        //   { value: '密处理(晚收批次)', label: '密处理(晚收批次)' },
        //   { value: '其他', label: '其他' }
        // ], // 加工方式集合
        process_list: [
          { value: this.$t('m.merchantworkbench.Triple_anaerobic'), label: this.$t('m.merchantworkbench.Triple_anaerobic') },
          { value: this.$t('m.merchantworkbench.Double_fermentation_in_the_sun'), label: this.$t('m.merchantworkbench.Double_fermentation_in_the_sun') },
          { value: this.$t('m.merchantworkbench.Kombucha_hair_western_sun_exposure'), label: this.$t('m.merchantworkbench.Kombucha_hair_western_sun_exposure') },
          { value: this.$t('m.merchantworkbench.solarization'), label: this.$t('m.merchantworkbench.solarization') },
          { value: this.$t('m.merchantworkbench.Aerobic_fermentation_in_the_sun'), label: this.$t('m.merchantworkbench.Aerobic_fermentation_in_the_sun') },
          { value: this.$t('m.merchantworkbench.Washing'), label: this.$t('m.merchantworkbench.Washing') },
          { value: this.$t('m.merchantworkbench.Wash_with'), label: this.$t('m.merchantworkbench.Wash_with') },
          { value: this.$t('m.merchantworkbench.wet_dig'), label: this.$t('m.merchantworkbench.wet_dig') },
          { value: this.$t('m.merchantworkbench.wet_dig_handle'), label: this.$t('m.merchantworkbench.wet_dig_handle') },
          { value: this.$t('m.merchantworkbench.dense_handle'), label: this.$t('m.merchantworkbench.dense_handle') },
          { value: this.$t('m.merchantworkbench.dense_handle_Late_Receipt_Batch'), label: this.$t('m.merchantworkbench.dense_handle_Late_Receipt_Batch') },
          { value: this.$t('m.merchantworkbench.other'), label: this.$t('m.merchantworkbench.other') }
        ], // 加工方式集合
        process_way: '', // 加工方式
        flavor_description: '' // 风味描述
      }),
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        subject_value: [{ required: true, message: '请选择品种', trigger: 'blur' }],
        brand: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
        price: [{ required: true, message: '请输入正确的价格', trigger: 'blur' }],
        unitOfMeasurement_value: [{ required: true, message: '请选择计量单位', trigger: 'blur' }],
        placeOfOrigin: [{ required: true, message: '请输入产地', trigger: 'blur' }],
        priceTerms_value: [{ required: true, message: '请选择报价方式', trigger: 'blur' }],
        currency_value: [{ required: true, message: '请选择币种', trigger: 'blur' }],
        offerTime: [{ required: true, message: '请选择报价日期', trigger: 'blur' }],
        modeOfPayment_value: [{ required: true, message: '请选择支付方式', trigger: 'blur' }],
        advanceRatio: [{ required: true, message: '请输入预付比例(1-100)', trigger: 'blur' }],
        leastIncrement: [{ required: true, message: '请输入最小增量', trigger: 'blur' }],
        allowOverflowRatio: [{ required: true, message: '请输入溢折比例(1-10)', trigger: 'blur' }],
        leastIndent: [{ required: true, message: '请输入最小订单量', trigger: 'blur' }],
        bringForwardTime_begin: [{ required: true, message: '请输入正确的预提提前天数', trigger: 'blur' }],
        dealWarehouse_value: [{ required: true, message: '请选择交收仓库', trigger: 'blur' }],
        feightPrice: [{ required: true, message: '请输入正确的运费价格', trigger: 'blur' }],
        manor_name: [{ required: true, message: '请输入咖啡庄园', trigger: 'blur' }],
        introductionToTheEstate: [{ required: true, message: '请输入咖啡庄园介绍', trigger: 'blur' }],
        grade: [{ required: true, message: '请输入咖啡等级', trigger: 'blur' }],
        authentication: [{ required: true, message: '请输入认证', trigger: 'blur' }],
        cuppingReference: [{ required: true, message: '请输入杯测参考', trigger: 'blur' }],
        treeSpecies: [{ required: true, message: '请输入树种', trigger: 'blur' }],
        productTime: [{ required: true, message: '请选择采摘年份', trigger: 'blur' }],
        altitude: [{ required: true, message: '请输入正确的种植海拔', trigger: 'blur' }],
        pick_way: [{ required: true, message: '请输入采摘方式', trigger: 'blur' }],
        // process_way: [{ required: true, message: '请选择加工方法', trigger: 'blur' }],
        flavor_description: [{ required: true, message: '请输入风味描述', trigger: 'blur' }]
      },
      loading: false,

      // 上传数据
      dir: null,
      fileList: ref([]),
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      },

      // 新增规格弹窗

      guigepic: ref(''), // 规格图片集合

      imgIndex: ref(''), // 当前的第几个规格

      GuigeWayDialog: ref(false),
      guigeDataNow: {
        spData: [
          {
            key: '交货日期',
            value: ''
          },
          {
            key: '规格',
            value: ''
          }
        ],
        price: null,
        stock: null,
        pic: '' // 展示图片
      },
      // 预提方式弹窗
      ytWayDialog: ref(false),
      ytDataNow: {
        spec: '',
        price: ''
        // minimum: ''
      }
    }
  },
  // *****现货发布*****重新发布预售*********
  props: ['preSta', 'lookDetails'],
  computed: {
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined
      },
      set: function (newValue) {
      }
    }
  },
  methods: {
    tobox (value) {
      this.toBox = value
      // return this.toBox
    },
    confirmblAdbill (str) { // 输入内容时正则判断数据
      switch (str) {
        case 'jg':
          this.formData.price = (parseInt(this.formData.price * 100) / 100).toFixed(2)
          if (!/(^[1-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.formData.price)) {
            this.formData.price = ''
          }
          break
        case 'yf':
          if (!/^([1-9][0-9]{0,1}|100)$/.test(this.formData.advanceRatio)) {
            this.formData.advanceRatio = ''
          }
          break
        case 'zl':
          if (!/^[1-9]\d*$/.test(this.formData.leastIncrement)) {
            this.formData.leastIncrement = ''
          }
          break
        case 'yz' :
          if (!/^([1-9]|10)$/.test(this.formData.allowOverflowRatio)) {
            this.formData.allowOverflowRatio = ''
          }
          break
        case 'dh' :
          if (!/^[1-9]\d*$/.test(this.formData.leastIndent)) {
            this.formData.leastIndent = ''
          }
          break
        case 'ytbegin':
          if (!/^[0-9]*$/.test(this.formData.bringForwardTime_begin)) {
            this.formData.bringForwardTime_begin = ''
          }
          break
        case 'ytfinish':
          if (!/^[0-9]*$/.test(this.formData.bringForwardTime_finish)) {
            this.formData.bringForwardTime_finish = ''
          }
          break
        case 'ggjg': // 规格价格
          this.guigeDataNow.price = (parseInt(this.guigeDataNow.price * 100) / 100).toFixed(2)
          if (!/(^[1-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.guigeDataNow.price)) {
            this.guigeDataNow.price = ''
          }
          break
        case 'ggstock': // 规格库存
          console.log(this.guigeDataNow.stock)
          if (!/^[1-9]\d*$/.test(this.guigeDataNow.stock)) {
            this.guigeDataNow.stock = ''
          }
          break
        case 'altitude':
          if (!/^[0-9]*$/.test(this.formData.altitude)) {
            this.formData.altitude = ''
          }
          break
        case 'ytjg': // 预提价格
          this.ytDataNow.price = (parseInt(this.ytDataNow.price * 100) / 100).toFixed(1)
          if (!/(^[0-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.ytDataNow.price)) {
            this.ytDataNow.price = ''
          }
          break
        case 'ytmin': // 预提最小量
          if (!/^[0-9]*$/.test(this.ytDataNow.minimum)) {
            this.ytDataNow.minimum = ''
          }
          break
        case 'yunfei': // 运费
          if (!/(^[1-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.formData.feightPrice)) {
            this.formData.feightPrice = ''
          }
          break
        default:
          break
      }
    },
    isfeightchoseChang (e) { // 现货更改运费触发
      console.log(e)
      // e === 0 ? '' : ''
    },
    async initdata () { // 初始化选择框数据
      // 初始化站点数据
      http.get('/mall-portal/pmsSite/selectList', {
        pageNum: 50,
        pageSize: 1
      }).then(res => {
        // console.log(res)
        this.formData.dealWarehouse_list = res.data.data
        // 重新发布时
        if (this.$props.lookDetails) {
          const deliveryPlace = this.$props.lookDetails.dealWarehouse_value
          // console.log(deliveryPlace)
          for (const i of this.formData.dealWarehouse_list) {
            if (deliveryPlace === i.name) {
              this.formData.dealWarehouse_valueid = i.id
              this.formData.dealWarehouse_value = i.name
              break
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
      // 品种(专题)数据
      http.get('/mall-portal/home/topicList')
        .then(res => {
          // console.log(res)
          this.formData.subject_list = res.data.data
          // 重新发布时
          if (this.$props.lookDetails) {
            const subjectId = Number(this.$props.lookDetails.subject_value)
            for (const i of this.formData.subject_list) {
              if (subjectId === i.id) {
                // this.formData.dealWarehouse_valueid = i.id
                // this.formData.dealWarehouse_value = i.name
                break
              }
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    handleRemove (file) { // 删除主图
      // console.log(file)
      // console.log(fileList)
      this.fileList = this.fileList.filter((item) => {
        return item.name !== file.name
      }) // 过滤方法
      // console.log(this.fileList)
    },
    guigehandleRemove (file) { // 删除 规格图

    },
    handlePictureCardPreview (file) { // 图片放大方法
      console.log(file)
      this.formData.decript.dialogImageUrl = file.url
      this.formData.decript.dialogVisible = true // 打开弹窗
    },
    beforeUpload (file) { // 主图上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 2024 * 20480 // 这里做文件大小限制
      if (!extension && !extension2 && !extension3 && !extension4) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg、gif格式!',
          type: 'warning'
        })
        return
      }
      if (!isLt2M) {
        ElMessage({
          message: '上传文件大小不能超过 20M!',
          type: 'warning'
        })
        return
      }
      const param = {
        prefix: 'ys/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            console.log(res)
            this.dir = res.data.data.dir
            this.upLoadData.key = `${res.data.data.dir}/${file.name}`
            this.upLoadData.policy = res.data.data.policy
            this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            this.upLoadData.signature = res.data.data.signature
            this.upLoadUrl = res.data.data.host
            // console.log(file)
            // console.log(this.fileList)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    guigebeforeUpload (file) { // 规格上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 200 // 这里做文件大小限制
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg格式!',
          type: 'warning'
        })
        return false
      }
      if (!isLt2M) {
        ElMessage({
          message: '上传文件大小不能超过 200k!',
          type: 'warning'
        })
        return false
      }
      const param = {
        prefix: 'guige/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            console.log(res)
            this.dir = res.data.data.dir
            this.upLoadData.key = `${res.data.data.dir}/${file.name}`
            this.upLoadData.policy = res.data.data.policy
            this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            this.upLoadData.signature = res.data.data.signature
            this.upLoadUrl = res.data.data.host
            // this.formData.specification_listData[this.imgIndex].price = file
            // console.log(file)
            // console.log(this.formData.specification_listData)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    uploadSuccess (res, file) {
      // console.log(res)
      // console.log(file)

      this.showFileList = true
      // this.fileList.pop()
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      this.fileList.push({ name: file.name, url: url })
      console.log(this.fileList)
      // this.emitInput(this.fileList[0].url)
    },
    guigeuploadSuccess (res, file) { // 规格图片改变触发方法
      // console.log(file)
      this.showFileList = true
      // this.formData.specification_listData.pop()
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      this.formData.specification_listData[this.imgIndex].pic = url
      console.log(this.formData.specification_listData)
    },
    guigeonChange (file, fileList) { // 暂时用不到
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name
      this.formData.specification_listData[this.imgIndex].pic = url
    },
    submitForm () { // 提交数据
      // ck.setCookie('token', '', 3)
      this.$refs.formData.validate((valid) => {
        // console.log(this.formData)
        this.loading = true
        if (valid) {
          if (this.fileList.length === 0) {
            ElMessage.error('请选择至少1张商品主图!')
            this.loading = false
            return
          }
          if (this.formData.specification_listData.length === 0) {
            ElMessage.error('请添加至少1种规格!')
            this.loading = false
            return
          }
          if (this.formData.withholdingway_listData.length === 0 && this.formData.previewStatus !== 0) {
            ElMessage.error({ message: '请添加至少1种预提方式!' })
            this.loading = false
            return
          }
          for (const i in this.formData) {
            // console.log(i)
            // if (i) {
            // }
            if (i.slice(-5) === 'begin' ||
                i.slice(-6) === 'finish'
                // i.slice(-6) === 'wRatio' ||
                // i.substring(0, 5) === 'least' ||
                // i.substring(0, 6) === 'advanc' ||
                // i.slice(-5) === 'price' ||
                // i.substring(0, 6) === 'produc'
            ) {
              this.formData[i] = Number(this.formData[i])
              continue
            }
            if (i === 'specification_listData') {
              for (const k in this.formData[i]) {
                this.formData[i][k].spData = JSON.stringify(this.formData[i][k].spData)
                // this.formData[i].spData = this.formData[i].spData.toString()
              }
            }
          }
          // for (const i in this.formData.specification_listData) {
          //   // console.log(i)
          //   // console.log(i.stock)
          //   // console.log(this.formData.leastIncrement)
          //   if (Number(this.formData.leastIncrement) > Number(i.stock)) {
          //     ElMessage.error({ message: '最小增量不能超出最小的规格库存！' })
          //     this.loading = false
          //     return
          //   }
          //   if (Number(this.formData.leastIndent) > Number(i.stock)) {
          //     ElMessage.error({ message: '最小订货量不能超出最小的规格库存！' })
          //     this.loading = false
          //     return
          //   }
          // }
          if (this.formData.bringForwardTime_begin === '' &&
           this.formData.bringForwardTime_finish === '') {
            ElMessage.error('请完善预提提前天数!')
            this.loading = false
            return
          }
          if (this.formData.bringForwardTime_begin >
           this.formData.bringForwardTime_finish) {
            ElMessage.error('请规范预提提前天数!')
            this.loading = false
            return
          }
          for (const i of this.formData.dealWarehouse_list) {
            // eslint-disable-next-line eqeqeq
            if (i.name == this.formData.dealWarehouse_value) {
              this.formData.dealWarehouse_valueid = i.id
              break
            }
          }
          if (!this.formData.process_way) {
            ElMessage.warning({
              message: '请选择加工方法'
            })
            this.loading = false
            return
          }
          const albumPics = this.fileList.map((item) => { return item.url }).join(',') // 图片拼接成字符串
          // console.log(albumPics)
          const pic = this.fileList[0].url
          const toFormData = {
            previewStatus: this.formData.previewStatus,
            productParam: null,
            qualityStandard: null,
            brandName: this.formData.brand,
            // brand: this.formData.brand, // 品牌
            currencySymbol: this.formData.currency_value,
            detailHtml: this.formData.decript.decriptList,
            isResale: Number(this.formData.turnMarket),
            minBuy: this.formData.leastIndent,
            minIncr: this.formData.leastIncrement,
            name: this.formData.name,
            offerExpiryDate: this.formData.offerTime + ' 23:59:59',
            offerForm: this.formData.priceTerms_value,
            originPlace: this.formData.placeOfOrigin, // 产地
            albumPics: albumPics,
            presaleWay: 1, // 预售方式
            allowOverflow: this.formData.allowOverflowRatio, // 允许溢价比例
            payWay: this.formData.modeOfPayment_value,
            pic: pic,
            pmsProductPreTakeWayList: toRaw(this.formData.withholdingway_listData), // 预提方式List
            pmsProductPresaleExtraEntityList: [
              {
                id: this.formData.bringForwardTime,
                advancePikegoodsDaysBegin: this.formData.bringForwardTime_begin,
                advancePikegoodsDaysEnd: this.formData.bringForwardTime_finish
              }
            ],
            pmsSiteList: [{
              id: this.formData.dealWarehouse_valueid
            }], // 交收仓库选择
            cmsCsSpecialSubjectEntityList: [
              {
                id: this.formData.subject_value
              }
            ], // 品种（专题）信息
            // pmsExchangeSkuStockEntity: null,
            productAttributeValueList: [{}],
            subjectProductRelationList: [{}],
            prePayRate: this.formData.advanceRatio,
            price: this.formData.price,
            deliveryPlace: this.formData.dealWarehouse_value, // 交收仓库
            skuStockList: toRaw(this.formData.specification_listData), // 规格List
            unit: this.formData.unitOfMeasurement_value, // 计量单位,
            freightChina: this.formData.isfeightchose === '0' ? this.formData.feightPrice + '/1' : null,
            freightAbroad: this.formData.isfeightchose === '1' ? this.formData.feightPrice + '/1' : null,
            manorName: this.formData.manor_name,
            altitude: this.formData.altitude,
            productTime: this.formData.productTime,
            pickWay: this.formData.pick_way,
            flavorDescription: this.formData.flavor_description,
            processWay: this.formData.process_way,
            introductionToTheEstate: this.formData.introductionToTheEstate, // 庄园介绍
            grade: this.formData.grade, // 等级
            authentication: this.formData.authentication, // 认证
            cuppingReference: this.formData.cuppingReference, // 杯测参考
            treeSpecies: this.formData.treeSpecies // 树种
          }
          console.log(toFormData)
          console.log(this.$props.lookDetails)
          // 重新发布位置
          if (this.$props.lookDetails && this.formStatus === 2) { // 预售重新发布调用
            console.log('预售重新发布的接口')
            againSub(this.formData.id, toFormData)
              .then(res => {
                if (res.data.code === 200) {
                  console.log(res)
                  emitter.emit('againSubon')
                  setTimeout(() => {
                    // window.location.reload() // 页面重新加载
                    this.loading = false
                  }, 1000)
                }
              }).catch(err => {
                console.log(err)
              })
          } else if (this.$props.lookDetails && this.formStatus === 3) { // 现货重新发布调用
            console.log('现货重新发布的接口')
            againSub(this.formData.id, toFormData)
              .then(res => {
                if (res.data.code === 200) {
                  console.log(res)
                  emitter.emit('againSubon')
                  setTimeout(() => {
                    window.location.reload() // 页面重新加载
                    this.loading = false
                  }, 1000)
                } else {
                  ElMessage.warning({
                    message: res.data.msg
                  })
                }
              }).catch(err => {
                console.log(err)
              })
          } else if (this.formStatus === 1) { // 现货新建发布
            console.log('现货发布')
            http.post('/mall-merchant/product/create', toFormData, true)
              .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                  ElMessage.success({
                    message: '发布成功!'
                  })
                  this.formData.specification_listData = []
                  setTimeout(() => {
                    window.location.reload() // 页面重新加载
                    this.loading = false
                  }, 1000)
                } else {
                  ElMessage.warning({
                    message: res.data.msg
                  })
                }
              }).catch(err => {
                console.log(err)
                this.loading = false
              })
          } else { // 预售发布
            console.log('预售发布')
            http.post('/mall-merchant/product/create', toFormData, true)
              .then(res => {
                console.log(res)
                if (res.data.code === 200) {
                  ElMessage.success({
                    message: '发布成功!'
                  })
                  this.formData.specification_listData = []
                  setTimeout(() => {
                    window.location.reload() // 页面重新加载
                    this.loading = false
                  }, 1000)
                } else {
                  ElMessage.warning({
                    message: res.data.msg
                  })
                }
              }).catch(err => {
                console.log(err)
                this.loading = false
              })
          }
        } else {
          this.loading = false
          ElMessage.error({ message: '请完善信息！！', duration: 1500, showClose: true })
        }
      })
    },
    resetForm () { // 取消提交
      // if (this.$props.lookDetails && (this.formStatus === 2 || this.formStatus === 3)) {
      //   this.$refs.formData.resetFields()
      // } else {

      // }
    },
    removeDatathis (item, index) { // 删除 该规格方法
      this.formData.specification_listData.splice(index, 1) // 删除某规格
      this.guigepic.splice(index, 1)
      this.imgIndex = ''
    },
    confirmGuigeWay () { // 确定增加规格
      if (this.guigeDataNow.spData[0].value !== '' &&
          this.guigeDataNow.spData[1].value !== '' &&
          this.guigeDataNow.price !== '' &&
          this.guigeDataNow.stock !== '') {
        if (this.formData.previewStatus !== 0) { // 预售
          // console.log(this.guigeDataNow)
          this.guigeDataNow.price = Number(this.guigeDataNow.price)
          this.formData.specification_listData.push(this.guigeDataNow)
          for (let i = 0; i < this.formData.specification_listData.length - 1; i++) { // 判断 规格日期是否已存在    1.0版本暂时使用一天一规格
            if (this.formData.specification_listData[i].spData[0].value === this.guigeDataNow.spData[0].value &&
            this.formData.specification_listData[i].spData[1].value === this.guigeDataNow.spData[1].value) {
              ElMessage.warning({ message: '同一天不能发布同样的规格！' })
              // ElMessage.warning({ message: '日期或者规格不能相同！' })
              this.formData.specification_listData.pop()
            }
          }
          this.guigeDataNow = { // 暂存清空
            spData: [
              {
                key: '交货日期',
                value: ''
              },
              {
                key: '规格',
                value: ''
              }
            ],
            price: null,
            stock: null,
            pic: ''
          }
          this.GuigeWayDialog = false
        }
      } else if (this.guigeDataNow.spData[1].value !== '' &&
          this.guigeDataNow.price !== '' &&
          this.guigeDataNow.stock !== '') { // 现货
        this.guigeDataNow.price = Number(this.guigeDataNow.price)
        this.formData.specification_listData.push(this.guigeDataNow)
        for (let i = 0; i < this.formData.specification_listData.length - 1; i++) { // 判断 规格日期是否已存在    1.0版本暂时使用一天一规格
          if (this.formData.specification_listData[i].spData[1].value === this.guigeDataNow.spData[1].value) {
            // ElMessage.warning({ message: '同一天不能发布同样的规格！' })
            ElMessage.warning({ message: '规格不能相同！' })
            this.formData.specification_listData.pop()
          }
        }
        this.guigeDataNow = { // 暂存清空
          spData: [
            {
              key: '交货日期',
              value: ''
            },
            {
              key: '规格',
              value: ''
            }
          ],
          price: null,
          stock: null,
          pic: ''
        }
        this.GuigeWayDialog = false
      } else {
        ElMessage.warning({
          message: '请完善规格信息！'
        })
      }
    },
    confirmytWay () { // 确定预提方式
      if (this.ytDataNow.spec !== '' &&
            this.ytDataNow.price !== '') {
        if (this.formData.previewStatus !== 0) { // 预售
          // console.log(this.guigeDataNow)
          this.ytDataNow.price = Number(this.ytDataNow.price)
          this.formData.withholdingway_listData.push(this.ytDataNow)
          this.ytDataNow = { // 清空数据
            spec: '',
            price: ''
            // minimum: ''
          }
          this.ytWayDialog = false
        }
      } else {
        ElMessage.warning({
          message: '请完善预提方式信息！'
        })
      }
    },
    removeytDataNow (item, index) { // 删除 该预提方式方法
      this.formData.withholdingway_listData.splice(index, 1)
    }
  },
  mounted () {
    this.initdata()

    if (this.$props.preSta === 0) { // 现货
      // console.log(typeof this.$props.preSta)
      this.formStatus = 1
      // this.formData.specification_dataTitle = ['规格', '销售价格', '库存', '属性图片', '操作']
      this.formData.specification_dataTitle = [
        this.$t('m.reservation_varietiy_details.specification'),
        this.$t('m.merchantworkbench.Sale_price'),
        this.$t('m.reservation_varietiy_details.guigeStock'),
        this.$t('m.merchantworkbench.Property_picture'),
        this.$t('m.auction.operate')
      ]
    }
    if (this.$props.lookDetails) {
      this.formStatus = this.$props.lookDetails.formStatus // 0：普通发布 1：现货发布  2：预售重新发布 3: 现货重新发布
      this.formData = this.$props.lookDetails // 重新发布给 默认初始数据
      this.fileList = []

      // 现货重新发布
      if (this.$props.lookDetails.formStatus === 3) {
        this.formData.specification_dataTitle = ['规格', '销售价格', '库存', '属性图片', '操作']
      }

      this.formData.id = this.$props.lookDetails.id
      const albumPics = this.$props.lookDetails.albumPics.split(',')
      for (const i of albumPics) {
        this.fileList.push({
          name: i,
          url: i
        })
      }
      this.guigepic = []
      for (const i of this.$props.lookDetails.specification_listData) {
        this.guigepic.push([
          {
            name: i.pic,
            url: i.pic
          }
        ])
      }
      // console.log(this.fileList)
    }
  },
  setup (props, ctx) {
    const datacop = getCurrentInstance()
    const editorDataList = (data) => {
      // console.log(data)
      datacop.data.formData.decript.decriptList = data
    }
    const disabledDate = (time) => {
      // 此条为设置禁止用户选择今天之前的日期，不包含今天。
      return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000))
    }
    return {
      disabledDate,
      editorDataList
    }
  }
}
</script>

<style lang="scss">
.fengwei{
  .el-form-item__content{
    .el-form-item__error{
      margin-top: 5px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.el-select__popper{
  z-index: 3001 !important;
}
.content_form{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  .el-form{
    .form_item{
      .fitem_title{
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        color: #C6824E;
        height: 50px;
        line-height: 50px;
      }
      .el-form-item{
        margin-bottom: 5px;
        .el-form-item__label{
          min-width: 100px;
        }
        .el-form-item__content{
          .el-input{
            width: 300px;
          }
          .el-form-item__error{
            padding: 0;
            margin-top: -5px;
            z-index: 2010;
          }
        }
      }
      .sptise{
        margin-top: 10px;
        .el-form-item__content{
          display: block;
          #tfhover{
            width: 880px;
            float: left;
            tr{
              height: 40px;
              display: flex;
              // align-items: center;
              th{
                flex: 1;
                background: #F7F7F7;
                padding:0 10px;
                color: #999999;
                text-align: left;
                border: 1px solid #EAEAEA;
              }
              td{
                padding:0 10px;
                flex: 1;
                margin-top: -1px;
                border: 1px solid #EAEAEA;
                .sptitebtn{
                  color: #999999;
                  cursor: pointer;
                }
                .postimg{
                  color: #C6824E;
                  cursor: pointer;
                }
              }
            }
            .heng{
              height: 50px;
              td{
                display: flex;
                // align-items: center;
                height: 100%;
                line-height: 50px;
                white-space: nowrap;
                overflow: auto;
              }
              ::-webkit-scrollbar{
                  width: 5px;
                  height: 5px;
                  background: #F7F7F7;
              }
              ::-webkit-scrollbar-thumb{
                background: #cccccc;
              }
              /deep/ .updata_img{
                align-items: center;
                div{
                  height: 46px;
                  .el-upload-list{
                    // padding: 0;
                    height: 46px;
                    // width: 0;
                    .el-upload-list__item{
                      margin-bottom: 0;
                      height: 46px;
                      width: 48px;
                    }
                  }
                  .el-upload--picture-card{
                    border: 0;
                    height: 0;
                    width: 0;
                    p{
                      height: 46px;
                      color: #C6824E;
                      cursor: pointer;
                      word-wrap:break-word;
                    }
                  }
                }
              }
            }
            tr:hover{
              td{
                transition: all 0.2s;
                background: #F7F7F7;
              }
            }
          }
          .el-button{
            float: left;
            margin-left: 10px;
          }
        }
      }
    }
    #rulese{
      display: block;
      .ruleseleft{
        float: left;
        margin-bottom: 10px;
        width: 400px;
        .el-form-item{
          .el-form-item__content{
            .el-select{
                width: 200px;
            }
            .el-input{
              width: 200px;
            }
          }
        }
        .biliitem{
          .el-form-item__content{
            .advbili{
              width: 120px;
            }
            .bili{
              margin-left: 10px;
              height: 30px;
              width: 80px;
            }
          }
        }
      }
      /deep/ .ruleseright{
        // margin-right: 800px;
        margin-bottom: 10px;
        float: left;
        .el-form-item{
          display: flex;
          .el-form-item__content{
            .el-select{
                width: 200px;
            }
            .el-input{
              width: 200px;
            }
          }
          .el-form-item__label{
            min-width: 130px !important;
          }
        }
        .biliitem{
          .el-form-item__content{
            .advbili{
              width: 120px;
            }
            .bili{
              margin-left: 10px;
              color: #999999;
              height: 30px;
              width: 80px;
            }
          }
        }
      }
      .withho{
        width: 100%;
        .el-form-item__content{
          display: block;
          #tfhover{
            width: 460px;
            float: left;
            tr{
              height: 40px;
              display: flex;
              line-height: 40px;
              th{
                flex: 1;
                text-align: left;
                background: #F7F7F7;
                padding:0 10px;
                color: #999999;
                border: 1px solid #EAEAEA;
              }
              td{
                padding:0 10px;
                flex: 1;
                margin-top: -1px;
                border: 1px solid #EAEAEA;
                .withhobtn{
                  color: #999999;
                  cursor: pointer;
                }
              }
            }
            tr:hover{
              td{
                transition: all 0.2s;
                background: #F7F7F7;
              }
            }
          }
          .el-button{
            float: left;
            margin-left: 10px;
          }
        }
      }
      .turnma,.turntime,.suppli{
        .el-form-item__label{
            min-width: 121px !important;
        }
      }
      .turntime{
        color: #999999;
        .el-form-item__content{
          .turntime_input{
            width: 80px !important;
            vertical-align: middle;
            .el-input__inner{
              padding: 0 5px;
            }
            .el-input-group__append{
              padding: 0 5px;
            }
          }
        }
      }
      .el-form-item{
        .el-form-item__content{
          .el-input{
            width: 200px;
          }
          .addbtn{
            margin-left: 10px;
            background: linear-gradient(0deg, #280E01 0%, #673000 100%);
            color: #ffffff;
          }
        }
      }
    }
    #coffeeset{
      display: block;
      .fitem_title{
        display: inline-block;
        width: 100%;
      }
      .ruleseleft{
        float: left;
        margin-bottom: 10px;
        width: 400px;
        .el-form-item{
          .el-form-item__content{
            .el-input{
              width: 200px;
            }
          }
        }
        .biliitem{
          .el-form-item__content{
            .advbili{
              width: 120px;
            }
            .bili{
              margin-left: 10px;
              height: 30px;
              width: 80px;
            }
          }
        }
      }
      .ruleseright{
        // margin-right: 800px;
        margin-bottom: 10px;
        float: left;
        .el-form-item{
          display: flex;
          .el-form-item__content{
            .el-select{
                width: 200px;
            }
            .el-input{
              width: 200px;
            }
          }
          .el-form-item__label{
            min-width: 130px !important;
          }
        }
        .biliitem{
          .el-form-item__content{
            .advbili{
              width: 120px;
            }
            .bili{
              margin-left: 10px;
              color: #999999;
              height: 30px;
              width: 80px;
            }
          }
        }
      }
      .ruleserbottom{
        width: 100%;
        float: left;
        .el-form-item{
          display: flex;
          .el-form-item__content{
            .el-radio-group{
              width: 450px;
            }
            .el-textarea{
              width: 600px;
            }
          }
          .el-form-item__label{
            min-width: 120px !important;
          }
        }
        .fengwei{
          .el-form-item__content{
            .el-form-item__error{
              // margin-top: 15px !important;
              line-height: 20px;
            }
          }
        }
      }
    }
    #decrip{
      display: block;
      // float: left;
      .el-form-item{
        margin-bottom: 12px;
        .el-form-item__content{
          .decripyt_div{
            font-family: Microsoft YaHei;
            font-weight: 400;
            .decripyt_div_sapn{
              font-size: 14px;
              color: #666666;
            }
            .decripyt_div_p{
              font-size: 12px;
              color: #999999;
            }
          }
          .pppp{
            color: #C6824E;
          }
          #editorshow{
            width:  800px;
            height: 400px;
            // background: transparent;
          }
          .el-overlay{
            z-index: 10000 !important;
            .el-overlay-dialog{
              .el-dialog{
                .el-dialog__body{
                  display: flex;
                  justify-content: center;
                  img{
                    width: 70% !important;
                  }
                }
              }
            }
          }
          .el-input--mini{
            max-width: 100px;
          }
        }
      }
      .fitem_title{
        display: inline-block;
        text-align: left;
      }
    }
    .form_btn{
      // margin-top: 50px;
      width: 100%;
      // float: left;
      .el-form-item__content{
        .el-button{
          width: 100px;
          height: 40px;
          color: #999999;
        }
        .submit_btn{
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          color: #ffffff;
          margin-right: 10px;
        }
      }
    }
  }
}
.el-overlay{
  .el-overlay-dialog{
    .el-dialog{
      .el-dialog__body{
        .el-form{
          .el-form-item{
            .el-form-item__label{
              text-align-last: justify;
              // padding: 0;
            }
            .el-form-item__content{
              .block{
                .el-input{
                  width: 100%;
                }
              }
            }
          }
        }
        .dialog-footer{
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}
</style>
