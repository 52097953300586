<template>
<!-- 发布现货 -->
  <div class="nowhuo">
    <trade-from :preSta=0></trade-from>
  </div>
</template>

<script>
import TradeFrom from './TradeFrom.vue'
export default {
  components: { TradeFrom },
  name: 'PostNowhuo',
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  setup () {
    return {

    }
  }
}
</script>

<style  lang="scss" scoped>
// .nowhuo{

// }
</style>
