<template>
  <div class="tinymce-editor">
    <editor v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick">
    </editor>
  </div>
</template>
<script>

import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/preview' // 预览插件
import 'tinymce/plugins/fullscreen' // 全屏插件
// import 'tinymce/plugins/imagetools' // 图片编辑插件
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'
import 'tinymce/icons/default/icons' // 解决了icons.js 报错Unexpected token '<'
// import http from '../api/http'
// import client from '../tools/OssClient.js'
import http from '../api/http'
// import OssClient from '../tools/OssClient.js'

export default {
  components: {
    Editor
  },
  props: {
    // 传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: { // 先引入插件
      type: [String, Array],
      default: 'lists image media table wordcount preview fullscreen'
    },
    toolbar: { // 调用插件
      type: [String, Array],
      default: 'undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image table preview | removeformat fullscreen'
    }
  },
  data () {
    return {
      // 初始化配置
      init: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        height: 400,
        skin_url: '/tinymce/skins/ui/oxide',
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        menubar: false,
        /*
           * 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
           * 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
           */
        images_upload_handler: (blobInfo, success, failure, progress) => {
          // console.log('blobInfo', blobInfo)
          console.log('blobUri', blobInfo.blobUri())
          const img = new Image()

          // console.log('uri', blobInfo.uri())
          const that = this

          /// xhrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr
          var xhr, formData, resData
          xhr = new XMLHttpRequest()
          xhr.withCredentials = false

          const param = {
            prefix: 'detail/'
          }
          xhr.open('POST', 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com')
          xhr.upload.onprogress = function (e) {
            progress(e.loaded / e.total * 100)
          }

          xhr.onload = function () {
            // var json
            if (xhr.status === 403) {
              failure('HTTP Error: ' + xhr.status, { remove: true })
              return
            }
            if (xhr.status < 200 || xhr.status >= 300) {
              failure('HTTP Error: ' + xhr.status)
              return true
            }
            // json = JSON.parse(xhr.responseText)
            // console.log(xhr)
            // success(json.body.msg)
            // return
          }
          http.get('mall-admin/aliyun/oss/policy', param)
            .then(res => {
              console.log(res)
              resData = res.data.data.host
              formData = new FormData()
              formData.append('key', `${res.data.data.dir}/${blobInfo.filename()}`)
              formData.append('policy', res.data.data.policy)
              formData.append('OSSAccessKeyId', res.data.data.accessKeyId)
              formData.append('success_action_status', 200)
              formData.append('signature', res.data.data.signature)
              formData.append('file', blobInfo.blob(), blobInfo.filename())
              // this.dir = res.data.data.dir
              // this.upLoadData.key = `${res.data.data.dir}/${file.name}`
              // this.upLoadData.policy = res.data.data.policy
              // this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
              // this.upLoadData.signature = res.data.data.signature
              // this.upLoadUrl = res.data.data.host
              // this.formData.specification_listData[this.imgIndex].price = file
              // console.log(file)
              // console.log(this.formData.specification_listData)
              // formData.append('endpoint', blobInfo.blobUri()) // 需要上传的文件 file
              // formData.append('bucketName', blobInfo.blobUri()) // 需要上传的文件 file
              xhr.send(formData)
              img.src = resData + `/${res.data.data.dir}/${blobInfo.filename()}`
              success(img.src)
            }).catch(err => {
              console.log(err)
            })
          /// xhrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr

          // img.src = `data:${blobInfo.blob().type};base64,${blobInfo.base64()}`
          if (that.imgNum >= that.totalImg) {
            failure('图片不能超过10张!')
          }
          img.onload = function () {
            if (img.width < 300 || img.height < 300) failure('上传图片分辨率不能低于300*300!')
            // console.log(img)
            that.imgNum += 1
            success(img.src)
          }
        }
      },
      myValue: this.value,
      imgNum: 0,
      totalImg: 10
    }
  },
  mounted () {
    // console.log(this.$data)
    tinymce.init(this.$data.init)
  },
  methods: {
    /*
       * 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
       * 需要什么事件可以自己增加
       */
    onClick (e) {
      // this.$emit('EditorFrom', e, tinymce)
      // this.$emit('EditorFrom', e)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear () {
      this.myValue = ''
    }
  },
  watch: {
    value (newValue) {
      this.myValue = newValue
    },
    myValue (newValue) {
      this.$emit('EditorFrom', newValue)
    }
  }
}
</script>
<style scoped>
</style>
