<template>
  <div class="posttrade">
    <el-tabs v-model="activeName">
      <el-tab-pane @tab-click="handleClick"
      v-for="(i,k) in panList" :key="k"
      :label="i.label"
      :name="i.name"
      >
      </el-tab-pane>
      <keep-alive>
        <component :is="activeName" :key="activeName">
        </component>
      </keep-alive>
    </el-tabs>
  </div>
</template>

<script>
import TradeFrom from './TradeFrom.vue'
import PostNowhuo from './PostNowhuo.vue'
import AuctionFrom from './AuctionFrom.vue'
import BarterFrom from './BarterFrom.vue'

export default {
  name: 'PostTradeMain',
  components: {
    TradeFrom, PostNowhuo, AuctionFrom, BarterFrom
  },
  data () {
    return {
      activeName: 'PostNowhuo',
      panList: [ // 发布管理列表
        // { label: this.$t('m.merchantworkbench.Release_pre_sale'), name: 'TradeFrom' },
        { label: this.$t('m.merchantworkbench.Release_from_stock'), name: 'PostNowhuo' },
        { label: this.$t('m.merchantworkbench.Publish_auction'), name: 'AuctionFrom' }
        // { label: this.$t('m.merchantworkbench.Release_Barter'), name: 'BarterFrom' }
      ] // 订单状态标题内容
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    }
  },
  mounted () {

  },
  setup () {
    return {}
  }
}
</script>

<style lang="scss">
.posttrade{
  .el-tabs{
    .el-tabs__header{
      .el-tabs__nav-wrap{
        .el-tabs__nav-scroll{
          display: flex;
          justify-content: center;
          .el-tabs__nav{
            margin: 0 auto;
            height: 70px;
            .el-tabs__active-bar{
              background: #C6824E;
            }
            .el-tabs__item{
              width: 200px;
              text-align: center;
              font-size: 18px;
              font-weight: bold;
              color: #C6824E;
              padding: 0;
            }
            .is-active{
              color: #C6824E;
            }
            .el-tabs__item:hover{
              color: #c49979;
            }
          }
        }
      }
    }
  }
}
</style>
