<template>
  <div>
    工作台
  </div>
</template>

<script>
export default {
  name: 'WorkBenchMain.vue',
  setup () {
    return {}
  }
}
</script>

<style lang="scss" scoped>

</style>
