<template>
<!-- 发布易货 -->
  <div class="content_form">
    <el-form
    :model="formData"
    label-width="120px"
    class="demo-ruleForm"
    ref="formData"
    :rules="rules"
    >
      <div id="essent" class="form_item">
        <span class="fitem_title">{{$t('m.merchantworkbench.Basic_information')}}</span>
        <el-form-item :label="$t('m.index.product_name')+'：'"
        prop="name">
          <el-input  maxlength="10" v-model="formData.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Product_Inventory')+'：'"
        prop="stock">
          <el-input v-model="formData.stock"
          @change="confirmblAdbill('stock')" maxlength="8"
          size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Unit_of_measurement')+'：'"
        prop="unitOfMeasurement_value">
          <el-select v-model="formData.unitOfMeasurement_value"
           size="mini">
            <el-option
              v-for="item in formData.unitOfMeasurement_option"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="规格："
        prop="guigeName">
          <el-input v-model="formData.guigeName" size="mini"></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('m.barter_detail.Self_estimated_price')"
        prop="price">
          <el-input v-model="formData.price" maxlength="8"
          @change="confirmblAdbill('jg')" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.barter.intended_exchange_commodity')+'：'"
        prop="demandProductName">
          <el-input maxlength="10" v-model="formData.demandProductName" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.barter_detail.Conversion_ratio')" class="lhb_exchangeRatio"
        prop="exchangeRatioStart">
          <el-input v-model="formData.exchangeRatioStart"
            maxlength="2"
            @change="confirmblAdbill('lhb1')" size="mini">
          </el-input>
          :
          <el-input v-model="formData.exchangeRatioEnd"
            maxlength="2"
            @change="confirmblAdbill('lhb2')" size="mini">
          </el-input>
        </el-form-item>
      </div>

      <el-divider style="background:#EAEAEA;"></el-divider> <!-- 分割线 -->

      <div id="rulese" class="form_item">
        <span class="fitem_title">{{$t('m.merchantworkbench.Rule_settings')}}</span>
        <el-form-item :label="$t('m.merchantworkbench.Effective_Date')+'：'"
          prop="offerTime">
          <div class="block">
            <el-date-picker v-model="formData.offerTime"
              size="mini"
              type="date"
              :placeholder="$t('m.merchantworkbench.option_date')"
              value-format="YYYY-MM-DD"
              :disabledDate="disabledDate"
              >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Deposit')+'：'"
        prop="earnestMoney">
          <el-input disabled v-model="bzj" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.barter_detail.place_of_delivery')"
        prop="leastIncrement">
          <el-input maxlength="15" v-model="formData.leastIncrement" size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.barter_detail.Logistics_compensation')"
        prop="freightChina">
          <el-input
          maxlength="8"
          v-model="formData.freightChina"
          @change="confirmblAdbill('wlmomeny')"
          size="mini"></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.barter_detail.handling_costs')"
        prop="carryMoney">
          <el-input
          maxlength="8"
          v-model="formData.carryMoney"
          @change="confirmblAdbill('zxmomeny')"
          size="mini"></el-input>
        </el-form-item>
      </div>

      <div id="decrip" class="form_item">
        <span class="fitem_title">{{$t('m.merchantworkbench.detailed_introduction')}}</span>
        <el-form-item :label="$t('m.merchantworkbench.Main_product_image')+'：'">
          <el-upload
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
            :on-preview="handlePictureCardPreview"
            :action="upLoadUrl"
            :data="upLoadData"
            :on-remove="handleRemove"

            list-type="picture-card"
            :limit="5"
            accept="jpg/jpeg/png/gif"
          >
            <el-icon><plus /></el-icon>
          </el-upload>
          <el-dialog v-model="formData.decript.dialogVisible">
            <img :src="formData.decript.dialogImageUrl" alt="" />
          </el-dialog>
          <div class="decripyt_div">
            <span class="decripyt_div_sapn">最多上传5张图片，点击更换图片</span>
            <p class="decripyt_div_p">支持jpg、jpeg、png、gif格式</p>
            <!-- <p class="decripyt_div_p">尺寸800px*800px，大小不超过500k</p> -->
          </div>
        </el-form-item>
        <el-form-item :label="$t('m.merchantworkbench.Product_detail_page')+'：'">
          <p class="pppp">（{{$t('m.merchantworkbench.Note_Please')}}）</p>
        </el-form-item>
        <el-form-item>
          <div id="editorshow" v-show="!formData.decript.dialogVisible">
            <editor-show @EditorFrom="(data)=>{editorDataList(data)}"></editor-show>
          </div>
        </el-form-item>
      </div>
      <el-form-item class="form_btn">
        <el-button class="submit_btn"
        :loading="loading"
        @click="submitForm()"
        >{{$t('m.merchantworkbench.submit')}}</el-button
        >
        <el-button @click="resetForm()"
        >{{$t('m.map.cancel')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
  <!-- 交保证金 -->
  <div v-if="showDialog" class="dialog_div">
    <el-dialog v-model="ConfirmInvoiceDialog" width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
      destroy-on-close
      :title="$t('m.merchantworkbench.Pay_deposit')">
      <p>{{$t('m.buy_settleaccounts.pay')+$t('m.merchantworkbench.Deposit')}}：{{'￥'+formData.earnestMoney}}</p>
      <div class="namebody">
        <el-input
          size="mini"
          placeholder="请输入支付密码"
          v-model="password"
          type="password"
          maxlength="6"
          minlength="6">
          </el-input>
        <el-button :loading="loadingbtn"
        @click="ConfirmBMoneyBtn">{{$t('m.merchantworkbench.Pay_deposit')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import EditorShow from '../../../components/EditorShow.vue'
import http from '../../../api/http.js'
import { getStore } from '../../../tools/storage'

// import { getStore } from '../../../tools/storage'
// import { policy } from '../../../api/oss.js'

// import Success from '../../components/Success.vue'

const pmsExchangeProduct = (data) => {
  return http.post('/mall-merchant/merchant/pmsExchangeProduct', data, true)
}
export default {
  components: { EditorShow },
  name: 'BarterFrom',
  data () {
    return {
      bzj: '20%',
      formData: reactive({
        // 基本信息
        name: '', // 商品名称
        stock: '', // 库存
        unitOfMeasurement_option: [{ value: 'KG', label: 'KG' }], // 所有单位
        unitOfMeasurement_value: 'KG', // 已选单位  // 计量单位
        // guigeName: '', // 规格
        price: '', // 自估价格
        demandProductName: '', // 拟易商品名称
        // exchangeRatio: 100, // 量换比
        exchangeRatioStart: '',
        exchangeRatioEnd: '',
        offerTime: '', // 有效日期
        earnestMoney: '', // 保证金
        leastIncrement: '', // 交货地
        freightChina: '', // 物流补偿金
        carryMoney: '', // 装卸补偿金
        // spData: [], // 规格

        decript: { // 商品介绍
          dialogImageUrl: ref(''), // 放大弹窗图片
          dialogVisible: ref(false), // 放大弹窗开关
          propertyImage: [], // 属性图片url
          url: [], // 图片地址
          decriptList: '' // 商品介绍
        }
      }),
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        stock: [{ required: true, message: '请输入正确的库存', trigger: 'blur' }],
        unitOfMeasurement_value: [{ required: true, message: '请选择计量单位', trigger: 'blur' }],
        // guigeName: [{ required: true, message: '请输入规格', trigger: 'blur' }],
        price: [{ required: true, message: '请输入正确的价格', trigger: 'blur' }],
        demandProductName: [{ required: true, message: '请输入拟易商品的名称', trigger: 'blur' }],
        exchangeRatioStart: [{ required: true, message: '请输入量换比例', trigger: 'blur' }],
        offerTime: [{ required: true, message: '请选择商品有效日期', trigger: 'blur' }],
        earnestMoney: [{ required: true, message: '请输入保证金', trigger: 'blur' }],
        leastIncrement: [{ required: true, message: '请输入交货地', trigger: 'blur' }],
        freightChina: [{ required: true, message: '请输入物流补偿金', trigger: 'blur' }],
        carryMoney: [{ required: true, message: '请输入装卸补偿金', trigger: 'blur' }]
      },
      loading: false,

      // 交保证金弹窗
      showDialog: false,
      ConfirmInvoiceDialog: false,
      loadingbtn: false,
      password: '',

      // 上传数据
      dir: null,
      fileList: [],
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      }
    }
  },
  watch: {
    'formData.price' (v, oldv) {
      this.formData.earnestMoney = (v * this.formData.stock) / 100 * 20
    },
    'formData.stock' (v, oldv) {
      this.formData.earnestMoney = (v * this.formData.price) / 100 * 20
    }
  },
  props: ['preSta'],
  computed: {
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined
      },
      set: function (newValue) {
      }
    }
  },
  methods: {
    confirmblAdbill (str) { // 输入内容时正则判断数据
      switch (str) {
        case 'jg': // 自估价格
          this.formData.price = (parseInt(this.formData.price * 100) / 100).toFixed(1)
          if (!/(^[1-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.formData.price)) {
            this.formData.price = ''
          }
          break
        case 'stock': // 库存限制 只能是数字
          if (!/^[1-9]\d*$/.test(this.formData.stock)) {
            this.formData.stock = ''
          }
          break
        case 'lhb1': // 量换比 // 只能是数字
          if (!/^[1-9]\d*$/.test(this.formData.exchangeRatioStart)) {
            this.formData.exchangeRatioStart = ''
          }
          break
        case 'lhb2': // 量换比 // 只能是数字
          if (!/^[1-9]\d*$/.test(this.formData.exchangeRatioEnd)) {
            this.formData.exchangeRatioEnd = ''
          }
          break
        case 'wlmomeny' : // 物流补偿金
          if (!/(^[0-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.formData.freightChina)) {
            this.formData.freightChina = ''
          }
          break
        case 'zxmomeny' : // 装卸补偿金
          if (!/(^[0-9]\d*\.?\d*)|(0\.\d*[1-9]$)/.test(this.formData.carryMoney)) {
            this.formData.carryMoney = ''
          }
          break
        case 'ytfinish':
          if (!/^[0-9]*$/.test(this.formData.bringForwardTime_finish)) {
            this.formData.bringForwardTime_finish = ''
          }
          break
        default:
          break
      }
    },
    handleRemove (file) { // 删除主图
      console.log(file)
      // console.log(fileList)
      this.fileList = this.fileList.filter((item) => {
        return item.name !== file.name
      }) // 过滤方法
      // console.log(this.fileList)
    },
    handlePictureCardPreview (file) { // 图片放大方法
      console.log(file)
      this.formData.decript.dialogImageUrl = file.url
      this.formData.decript.dialogVisible = true // 打开弹窗
    },
    beforeUpload (file) { // 主图上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 2024 // 这里做文件大小限制
      if (!extension && !extension2 && !extension3 && !extension4) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg、gif格式!',
          type: 'warning'
        })
        // this.handleRemove(file)
        return
      }
      if (!isLt2M) {
        ElMessage({
          message: '上传文件大小不能超过 500k!',
          type: 'warning'
        })
        // this.handleRemove(file)
        return
      }
      const param = {
        prefix: 'yh/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            // console.log(res)
            this.dir = res.data.data.dir
            this.upLoadData.key = `${res.data.data.dir}/${file.name}`
            this.upLoadData.policy = res.data.data.policy
            this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            this.upLoadData.signature = res.data.data.signature
            this.upLoadUrl = res.data.data.host
            this.fileList.push(file)
            // console.log(this.fileList)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    uploadSuccess (res, file) {
      // console.log(res, file)

      this.showFileList = true
      this.fileList.pop()
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      this.fileList.push({ name: file.name, url: url })
      // this.emitInput(this.fileList[0].url)
      // console.log(this.fileList)
    },
    async submitForm () { // 提交数据
      // ck.setCookie('token', '', 3)
      this.$refs.formData.validate((valid) => {
        // console.log(this.formData)
        this.loading = true
        if (valid) {
          console.log(this.fileList)
          if (this.fileList.length === 0) {
            ElMessage.error('请选择至少1张商品主图!')
            this.loading = false
            return
          }
          if (this.formData.exchangeRatioStart === '' || this.formData.exchangeRatioEnd === '') {
            ElMessage.warning('请输入正确的量换比!')
            this.loading = false
            return
          }
          // 弹出支付保证金密码
          // const res = await verifyThatTheMarginIsCompliantBeforeCreating(data)
          this.showDialog = true
          this.ConfirmInvoiceDialog = true
          // this.formData.spData = [
          //   { key: '有效期', value: this.formData.offerTime },
          //   { key: '规格', value: this.formData.guigeName }
          // ]
          // this.formData.spData = JSON.stringify(this.formData.spData)
          this.loading = false
        } else {
          this.loading = false
          ElMessage.error({ message: '请完善信息！！', duration: 1500, showClose: true })
        }
      })
    },
    resetForm () { // 取消提交
      this.$refs.formData.resetFields()
    },
    async ConfirmBMoneyBtn () { // 交保证金按钮
      this.loadingbtn = true
      const loading = this.$loading({
        lock: true,
        text: '加载中...'
      })
      const pic = this.fileList[0].url
      const albumPics = this.fileList.map((item) => { return item.url }).join(',') // 图片拼接成字符串
      // console.log(albumPics)
      const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
      // console.log(this.password)
      const md5password = this.$md5(`${capitalAccount}${this.password}`)
      const toFormData = {
        password: md5password, // 支付保证金密码
        // previewStatus: 3, // 是否为预告商品：0->不是；1->是；3->易货商品
        publishStatus: null, // 上架状态
        albumPics: albumPics, // 商品主图
        name: this.formData.name, // 商品名称
        unit: this.formData.unitOfMeasurement_value, // 计量单位
        deliveryPlace: this.formData.leastIncrement, // 交货地
        freightChina: this.formData.freightChina, // 物流补偿金
        skuStockList: [{
          demandProductName: this.formData.demandProductName, // 拟易名称
          exchangeRatio: this.formData.exchangeRatioStart + ':' + this.formData.exchangeRatioEnd, // 量换比
          earnestMoney: this.formData.earnestMoney, // 保证金
          // spData: this.formData.spData, // 规格
          price: this.formData.price, // 自估价格
          stock: this.formData.stock, // 商品库存
          carryMoney: this.formData.carryMoney // 装卸补偿金
        }],
        offerExpiryDate: this.formData.offerTime + ' 23:59:59', // 有效日期
        pic: pic, // 商品首图
        detailHtml: this.formData.decript.decriptList // 富文本
      }
      console.log(toFormData)
      pmsExchangeProduct(toFormData)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            this.loadingbtn = false
            ElMessage.success({
              message: '发布成功!请等待审核'
            })
            setTimeout(() => {
              window.location.reload() // 页面重新加载
              this.password = ''
              this.loading = false
            }, 200)
          } else {
            ElMessage.error({
              message: res.data.msg
            })
            this.showDialog = false
            this.ConfirmInvoiceDialog = false
            this.password = ''
          }
          this.loadingbtn = false
        }).catch(err => {
          console.log(err)
        })
      loading.close()
      // setTimeout(() => {
      //   window.location.reload() // 页面重新加载
      // }, 1000)
    }
  },
  mounted () {
    // this.initdata()
  },
  setup (props, ctx) {
    const datacop = getCurrentInstance()
    const editorDataList = (data) => {
      // console.log(data)
      datacop.data.formData.decript.decriptList = data
    }
    const disabledDate = (time) => {
      // 此条为设置禁止用户选择今天之前的日期，不包含今天。
      return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000))
    }
    return {
      disabledDate,
      editorDataList
    }
  }
}
</script>

<style lang="scss">
.el-select__popper{
  z-index: 3001 !important;
}
.content_form{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  .el-form{
    .form_item{
      .fitem_title{
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: #C6824E;
        height: 50px;
        line-height: 50px;
      }
      .el-form-item{
        margin-bottom: 5px;
        .el-form-item__label{
          width: 100px;
        }
        .el-form-item__content{
          .el-input{
            width: 300px;
          }
          .el-form-item__error{
            padding: 0;
            margin-top: -3px;
            z-index: 2010;
          }
        }
      }
      .lhb_exchangeRatio{
        .el-form-item__content{
          .el-input{
            width: 70px;
          }
          .el-form-item__error{
            padding: 0;
            margin-top: -3px;
            z-index: 2010;
          }
        }
      }
    }
    #rulese{
      display: block;
      .turnma,.turntime,.suppli{
        .el-form-item__label{
            width: 121px !important;
        }
      }
      .turntime{
        color: #999999;
        .el-form-item__content{
          .turntime_input{
            width: 80px !important;
            vertical-align: middle;
            .el-input__inner{
              padding: 0 5px;
            }
            .el-input-group__append{
              padding: 0 5px;
            }
          }
        }
      }
      .el-form-item{
        .el-form-item__content{
          .el-input{
            width: 200px;
          }
          .addbtn{
            margin-left: 10px;
            background: linear-gradient(0deg, #280E01 0%, #673000 100%);
            color: #ffffff;
          }
        }
      }
    }
    #decrip{
      .el-form-item{
        .el-form-item__content{
          .decripyt_div{
            font-family: Microsoft YaHei;
            font-weight: 400;
            .decripyt_div_sapn{
              font-size: 14px;
              color: #666666;
            }
            .decripyt_div_p{
              font-size: 12px;
              color: #999999;
            }
          }
          .pppp{
            color: #C6824E;
          }
          #editorshow{
            width:  800px;
            height: 400px;
            // background: transparent;
          }
          .el-overlay{
            z-index: 10000 !important;
            .el-overlay-dialog{
              .el-dialog{
                .el-dialog__body{
                  display: flex;
                  justify-content: center;
                  img{
                    width: 70% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .form_btn{
      margin-top: 50px;
      .el-form-item__content{
        .el-button{
          width: 100px;
          height: 40px;
          color: #999999;
        }
        .submit_btn{
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          color: #ffffff;
          margin-right: 10px;
        }
      }
    }
  }
}
.el-overlay{
  .el-overlay-dialog{
    .el-dialog{
      .el-dialog__body{
        .el-form{
          .el-form-item{
            .el-form-item__label{
              text-align-last: justify;
              // padding: 0;
            }
            .el-form-item__content{
              .block{
                .el-input{
                  width: 100%;
                }
              }
            }
          }
        }
        .dialog-footer{
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}
</style>
